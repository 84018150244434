export class AuthenticationService {

    public static async getConfiguration(currentLocation: string): Promise<any> {

        var response = await fetch('/okta-config.json');

        if (response.ok) {
            let result: AuthenticationConfiguration = {
                "issuer": "https://iress.oktapreview.com/oauth2/default",
                "redirectUri": "",
                "clientId": "0oanu70y7hpHU40Pt0h7",
                "pkce": false
            };
            result.redirectUri = currentLocation + '/implicit/callback';
            return result;
        }

        throw new Error("Http error getting okta config" + response.status + ':' + response.statusText);
    }
}

export interface AuthenticationConfiguration {
    issuer: string,
    redirectUri: string,
    clientId: string,
    pkce: boolean
}