import React from 'react';
import applicationSupport from './content/application-support'
import tas from './content/tas';
import configHub from './content/configHub';
import UserGuide from './user-guide';
import { IressStack, IressPanel, IressButton, IressRow, IressCol, ColSize, IressInline, InlineAlign, InlineGutter, ButtonMode, IressText, TextElement, RowGutter, RowJustifyContent, PanelPadding, StackGutter, IressSelect, IressIcon } from "@iress/components-react";

type UserGuideRowProps = {
    title: string;
    description: string;
    lastUpdatedRelease: string;
    markdown: string;
    rootUrl?: string;
    pdfUrl?: string;
}

class UserGuideRow extends React.Component<UserGuideRowProps, { open: boolean, markdown: string, rootUrl?:string }> {
    constructor(props: UserGuideRowProps) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            open: false,
            markdown: this.props.markdown,
            rootUrl: this.props.rootUrl
        });
    }

    render() {
        return (<IressPanel padding={PanelPadding.Sm}>
            <IressRow gutter={RowGutter.Sm} justifyContent={RowJustifyContent.Between}>
                <IressCol>
                    <IressRow>
                        <IressText element={TextElement.H1}>{this.props.title}</IressText>
                    </IressRow>
                    <IressRow>
                        <IressText text={this.props.description}></IressText>
                    </IressRow>
                </IressCol>
                <IressCol col={ColSize.Five} colSm={ColSize.Auto}>
                    <IressInline align={InlineAlign.Right} gutter={InlineGutter.Sm}>
                        <IressSelect>
                            <option value="latest">{this.props.lastUpdatedRelease} (Latest)</option>
                        </IressSelect>
                        <IressButton onClick={() => this.setState({ open: !this.state.open, markdown: this.props.markdown })} mode={ButtonMode.Primary}>
                            <IressIcon name="eye" iconTitle="View" slot={IressButton.Slot.Start} />
                            {this.state.open ? 'Close' : 'View'}
                        </IressButton>
                        <IressButton href={this.props.pdfUrl} target={'new'}>
                            <IressIcon name="download" iconTitle="Download" slot={IressButton.Slot.Start} />
                            Download
                        </IressButton>
                    </IressInline>
                </IressCol>
            </IressRow>
            {
                this.state.open ? <div><hr></hr><UserGuide markdownString={this.state.markdown} rootUrl={this.state.rootUrl}></UserGuide></div> : null
            }
        </IressPanel>);
    }
}

class UserGuides extends React.Component<{}, UserGuidesState> {
    setMarkdown(): void {
        ;
    };

    constructor(props: any) {
        super(props);
    }

    componentWillMount() {
        let userGuidesProps = [
            {
                title: 'Task Administration System',
                description: 'This document details the processes for setting up task views and administrating task instances in exception scenarios.',
                lastUpdatedRelease: '2020.5.1',
                markdown: tas,
                rootUrl: 'https://github.com/oneiress/mso-app-tas/raw/master/Documentation/',
                pdfUrl: 'https://buildkite.com/organizations/oneiress/pipelines/mso-task-administration-system-tas/builds/16/jobs/50a8fe57-0e63-47a7-9afa-1652e961b29e/artifacts/51205de5-5765-4a75-9374-6f7457e32abc'
            },
            {
                title: 'Configuration Hub',
                description: 'This document provides a walkthrough of the MSO Configuration Hub which enables authenticated and authorised Configuration Users to amend existing configurable items.',
                lastUpdatedRelease: '2019.12.1',
                markdown: configHub,
                pdfUrl: 'https://buildkite.com/organizations/oneiress/pipelines/mso-configuration-hub/builds/3/jobs/b4b0a517-51ec-4b48-9a48-a6c0bb928b51/artifacts/14018216-2c32-4360-9289-722ce34c7ad7'
            },
            {
                title: 'Application Support',
                description: 'This document describes the features available in the Application Support module of MSO.',
                lastUpdatedRelease: '2020.6.1',
                markdown: applicationSupport,
                rootUrl: 'https://github.com/oneiress/mso-app-application-support/raw/master/Documentation/',
                pdfUrl: 'https://buildkite.com/organizations/oneiress/pipelines/mso-application-support/builds/13/jobs/9cd48367-044b-49c5-93bb-8d24ab259c95/artifacts/66b26693-8d2e-4863-a6af-a6368d2dcfb6'
            }
        ];

        this.setState({
            userGuides: userGuidesProps.sort((a, b) => a.title == b.title ? 0 : a.title > b.title ? 1 : -1)
        });
    }

    reverseClick(e: Event) {
        e.preventDefault();
        this.setState(() => {
            return {
                userGuides: this.state.userGuides.reverse()
            }
        });
    }

    render() {
        return (<div>

            <IressText element={TextElement.H1}>The Library</IressText>

            <IressText element={TextElement.H2}>User Guides</IressText>

            <IressStack gutter={StackGutter.Md}>

                {/* <IressInline>
                    <IressButton>
                        <IressIcon name="download" iconTitle="Download" slot={IressButton.Slot.Start} />
                        Download all
                    </IressButton>
                    <IressButton onClick={this.reverseClick.bind(this)}>
                        <IressIcon name="sort" iconTitle="Sort" slot={IressButton.Slot.Start} />
                        Sort
                    </IressButton>
                </IressInline> */}
                {
                    this.state.userGuides.map(userGuide => <UserGuideRow {...userGuide}></UserGuideRow>)
                }
            </IressStack>
        </div>);
    }
}

type UserGuidesState = {
    markdownString?: string;
    userGuides: UserGuideRowProps[];
}

export default UserGuides;