export default `# Configuration Hub

## Introduction

This document provides a walkthrough of MSO Configuration Hub.

The MSO Configuration Hub allows authenticated and authorised Configuration Users to amend MSO's existing configurable items – parameters, messages and reference data. Configuration changes must be approved before they take effect in MSO. The Configuration Hub cannot be used to create or delete any configurable items.

Values for configuration items can be set according to channel e.g. intermediary and also according to brand.

### Parameters

In MSO a parameter is a named entity that has an associated value. They are typically used to substitute values in rules and messages. A Lender can configure the value of a parameter.

### Messages

In MSO a message is an instance of text displayed on the user interface. Messages can be information, warnings or error messages. The majority of messages are static text, however, messages can contain variables (e.g. parameters) that will be substituted with appropriate values at the time when they are to be displayed on the user interface. Please note there are no configurable messages in Originations.

A Lender can configure the text of a message to suit the target audience of the message.

### Reference data

In MSO reference data are sets of enumerated values, used to drive the options that are available to a user in a pick list.

A Lender can configure the following for each data item set:

- Which items from the list should be available;
- The order in which the items should appear; and
- The caption shown in the user interface for each item.

This means reference data configuration can be used to restrict certain activities in the Sales channel, for example, removing 'Buy to Let' from the available application types would hide all Buy to Let specific data capture fields from the rest of the user interface and would not allow Buy to Let applications to be submitted through the API.

Note: Some reference data items are defined as 'system-only' so cannot be changed by clients.

## User Access

The Configuration Hub is only accessible to internal users who have been granted the 'Configuration User' user type within User Management.
Configuration Users with the appropriate roles assigned can view, edit or approve. Unless otherwise stated this guide assumes that a user has been granted sufficient authorisation to perform the functionality described. Please see the User Management FDS for a description of the standard roles that can be assigned to a Configuration User.

## Item Search

Once logged into Configuration Tooling the homepage allows:

- A configuration item to be searched for
- Items introduced in a specified release to be searched for
- A configuration item to be selected from recently used items
- Navigation to change requests that are 'awaiting approval'
- Navigation to historic change requests that have already been approved or rejected
- The user to log off or change their security questions

**Figure 1: Configuration Hub homepage**

If the user chooses to use the search bar, all items that contain the search string will be retrieved, along with their description. They can then use the filters on the left to further refine the search.

![Hub HomePage](Images/Config-Hub.png)

**Figure 2: Item search**

If the user enters/selects a release, all items that were introduced in that release will be listed.

![](Images/Item-Search.png)

**Figure 3: Release search**

The user can then select multiple releases and/or specific types of configuration (example below displays all messages introduced in releases 2019.7.1 and 2019.9.1)

![](Images/Item-Search-Release.png)

**Figure 4: Releases and item type filters**

## Updating Parameters and Messages

Once the required item has been found, it can be selected and the user will see a summary of that item, detailing its value for all channel and brand combinations. The filters on the left can be used to control which brand/channel combinations that information is displayed for.

A History tab is available displaying all the change requests for the specific configuration item.

**Note:** If no brands are defined or if only one brand is defined, only channel filters and values will be displayed and there will be no brand column in the current values table.

![](Images/Update-Parameter.png)

**Figure 5: Configuration item summary**

By clicking the update value button, the user can input a new value and select the channel/brand combinations that they want the new value to be effective in. The user must enter a comment and can also select an effective date.

**Note:** If the approval date is later than the effective date, then the change will be effective as of the approval date.

If the approval date is earlier than the effective date, then the change will be applied as of the effective date.

![](Images/Configuration-Item-Summary.png)

**Figure 6: Update item value**

Once the new value has been entered and the required channel/brands have been selected, the user can, depending on their role, send the change request for approval or approve the change immediately (see Figure 8).

**Note:** A comment must be entered

Once one of the buttons has been pressed to progress the update request, a confirmation screen will appear with a summary of the changes being made.

![](Images/Update-Confirmation.png)

**Figure 7: Send change for approval with effective date**

![](Images/Send-For_Approval-Effective-Date.png)

**Figure 8: Approve immediately**

## Updating Reference Data

Reference data is more complicated than parameters and messages, as each reference data set contains multiple values. For each value, it is possible to change 3 attributes: the caption, the order and whether the value is active/inactive.
If a caption has an ![Info](Images/Information.png) next to it, this means the caption was added in a later release to the parent reference data set. The actual release it was added in can be viewed by hovering the pointer over the ![Info](Images/Information.png).

**Note:** If no brands are defined or if only one brand is defined, there will be no brand column in the current values table.

![](Images/View-Reference-Data.png)

**Figure 9: Viewing reference data**

To update reference data, click the update values button and the screen below will be presented.

![](Images/Updating-Reference-Data.png)

**Figure 10: Updating reference data**

Then select a channel and brand (if more than one is defined). The intermediary channel and the default brand, if there is more than one brand, are pre-selected on first entry. The user can enter a new caption, make a value active/inactive or change the display order (by dragging and dropping rows).

**Note:** To change a value in all channels and brands, first change the required item in the combination displayed at the top, then 'select all' for the other combinations in the channel/brand list lower down the screen.

## Approving a change request

Where an item has been sent for approval, it will end up on the list of change requests awaiting approval. On the top bar, select the clipboard icon and you will be presented with the change requests that are awaiting approval, with the oldest at the top.

![](Images/Unapproved-Change-Request.png)

**Figure 11: Viewing unapproved change requests**

To approve or reject a change request, click the corresponding button on the right.

The approve/reject screen will appear and the user will be able to select the approve or reject button.

![](Images/Approve-Reject.png)

**Figure 12: Approve/Reject change request**

After clicking the approve/reject button, the user will see a confirmation pop-up that they can enter comments into.

![](Images/Approve-Reject-Confirmation.png)

**Figure 13: Approve/Reject Confirmation**

**Note:** The approver must enter a comment if they choose to reject the change request.

## Viewing historic change requests

On the top bar, click the history icon to view historic change requests

![](Images/Historic-Change-Requests-Navigation.png)

**Figure 14: Navigate to historic change requests**

The user will then be presented with the screen below, with change requests in descending review date order.

![](Images/Historic-Change-Requests.png)

**Figure 15: Historic change requests**

Clicking on an item will allow the user to see the content of the change request.

![](Images/Change-Request-Content.png)

**Figure 16: Change request content**

## Which channel's parameters are used where?

MSO has 4 different channels, each with their own set of parameters. The table below explains where each parameter channel is used.

| MSO Area                | Parameter Channel | Notes                                                     |
| ----------------------- | ----------------- | --------------------------------------------------------- |
| Intermediary Log-in     | Intermediary      | Internal intermediary users will use internal user values |
| Panel Management Log-in | Intermediary      | Panel Management users will use internal user values      |
| All other Log-ins       | Backoffice        |                                                           |
| Intermediary Sales      | Intermediary      |                                                           |
| Direct/Branch Sales     | Direct            |                                                           |
| Digital Sales           | Digital           |                                                           |
| Originations/Desktop    | Backoffice        |                                                           |
| User Admin              | Backoffice        |                                                           |
| Tooling                 | Backoffice        |                                                           |
| Product Tooling         | Backoffice        |                                                           |
| TAS                     | Backoffice        |                                                           |
| Aplication Support      | Backoffice        |                                                           |
| Panel Management        | Intermediary      |                                                           |

## How do I change my security questions and answers?

The process for changing your security question and answers can be initiated from within any MSO back office system, which includes Configuration Tooling:

1. From the Configuration Tooling homepage click on your email address from within the header bar, a dropdown list will appear.
1. Click on the [Edit your security questions] link in the dropdown list.
1. On the new page select three new security questions.
1. Enter the answers to the security questions.
1. Enter your account password.
1. Click the [Submit] button.

It's important to note that for security reasons MSO will not display your existing security questions and answers, and therefore, MSO requires you to submit a full set of three security questions and answers when updating; i.e. it is not possible to only update one or two of the questions and answers during the process.

The answers to your security questions are stored salted and hashed in the User Management database.

These security questions and answers are for your MSO account as a whole, and are used for any MSO site that you have access to.

![](Images/Edit-Security-Questions.png)

**Figure 17: Select the Edit your security questions link from the header bar**

![](Images/New-Security-Questions.png)

**Figure 18: Select three new security questions, enter their answers and confirm your password**

## Appendix A - Version History

| Release   | Updates                                                                                                                                                                                        |
| --------- | ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| 2019.12.1 | Screenshots updated.                                                                                                                                                                           |
| 2019.10.1 | Added: - Ability to search for items according to the release they were introduced - Cater for installations where no brands are defined or only one brand is defined Also screenshots updated |
| 2019.5.1  | Included reference data.                                                                                                                                                                       |
| 2019.2.1  | Issued.                                                                                                                                                                                        |

- Valid actions are Create, Modify, Review, and Approve

## Appendix B - References

| Name of document                             | Description                                                                                                                                          |
| -------------------------------------------- | ---------------------------------------------------------------------------------------------------------------------------------------------------- |
| FDS_MSO_ConfigurationAndSystemItems document | Document detailing all the configuration and system items with MSO.                                                                                  |
| FDS_MSO_UserManagement                       | A FDS which describes the MSO module that handles the management of internal users, including users who are authorised to use the Configuration Hub. |
| USG_MSO_Glossary                             | Document containing a glossary of terms used throughout the Iress MSO system and its supporting documentation.                                       |
| USG_MSO_UserManagement                       | A user guide which describes the process of setting up internal users who are authorised to use the Configuration Hub.                               |

## Appendix C - Terminology and Abbreviations

| Term              | Description                                                                 |
| ----------------- | --------------------------------------------------------------------------- |
| Configurable item | The individual item whose value can be changed using the Configuration Hub. |
| Configurable item | A logical grouping of configurable items e.g. parameters, messages etc.     |
`;