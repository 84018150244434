import React from 'react';

class Fds extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (<div>This is Fds</div>);
    }
}

export default Fds;