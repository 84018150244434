import React from 'react';
import Markdown from 'react-markdown';
import { IressButton, IressIcon, IressPanel, PanelPadding, IressText } from "@iress/components-react";

class UserGuide extends React.Component<UserGuideProps, {}> {
    constructor(props: any) {
        super(props);
    }

    style: React.CSSProperties = {
        minHeight: '30px',
        height: '500px',
        overflowY: "scroll",
        resize: "vertical"
    };

    render() {
        if (this.props.markdownString) {
            return (
            <div style={this.style}>
                <IressText className='markdown'>
                    <Markdown source={this.props.markdownString} transformImageUri={(url) => this.props.rootUrl + url}/>
                </IressText>
            </div>
            );
        }

        return <div></div>;
    }
}

export default UserGuide;

type UserGuideProps = {
    markdownString: string;
    rootUrl?: string;
}

