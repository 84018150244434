import React from 'react';
import { Backdrop } from '@iress/oui/lib/components/Backdrop/Backdrop';

interface Props {
    isActive: boolean;
}

const loading: React.FC<Props> = (props:Props) => {
    return (
        <>
        <Backdrop active={props.isActive} isTinted>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
            } as React.CSSProperties}>
            <div className="fa fa-circle-o-notch fa-4x fa-spin" />
          </div>
        </Backdrop>
      </>
    )
}

export default loading;