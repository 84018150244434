import React from 'react';
import { IressCol, ColSize, ColAlignSelf, IressText, IressPanel } from '@iress/components-react'

interface ContentProps {
    title:string;
    description:string;
    linkUrl: string;
    imageUri:string;
}

type Props = ContentProps;

const homeSection: React.FC<Props> = (props: Props) => {

    return (
        <IressCol col={ColSize.Twelve} colSm={ColSize.Six} colMd={ColSize.Four}  alignSelf={ColAlignSelf.Stretch} >
            <a href={props.linkUrl}>
            <IressPanel stretch>
            <IressText>
                <a href={props.linkUrl}><img width='100%' src={props.imageUri} alt={props.description} /></a>
                <h3><a href={props.linkUrl}>{props.title}</a></h3>
                <p>{props.description}</p>
            </IressText>
            </IressPanel>
            </a>
        </IressCol>
    );
}

export default homeSection;