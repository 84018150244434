import React from 'react';

class DocumentPacks extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (<div>This is DocumentPacks</div>);
    }
}

export default DocumentPacks;