import React, { useState, useEffect } from 'react';
import { default as Section } from './homeSection';
import { Fieldset } from '@iress/oui/lib/components/Fieldset/Fieldset';
import { Label } from '@iress/oui/lib/components/Label/Label';
import { SingleSelect } from '@iress/oui/lib/components/SingleSelect/SingleSelect';
import {SdsService} from '../../services/sdsService';
import {default as Loading} from '../loading';

import dataContractimage from './images/data-contracts-image.webp';
import fdsImage from './images/fds-image.jpg';
import implementationGuidesImage from './images/implementation-guides-image.jpg';
import sdsImage from './images/sds-image.jpg';
import userGuidesImage from './images/user-guides-image.jpg';
import { IressText, TextElement, IressRow, RowGutter, RowJustifyContent, RowAlignItems, IressCol, ColSize } from '@iress/components-react'

interface ContentProps {

}

interface StateProps {
    selectedSds: ISdsData
    sdsList: Array<ISdsData>
}

interface ISdsData {
    value: any,
    label: string
}

interface DispatchProps {

}

type Props = ContentProps & StateProps & DispatchProps;


async function loadSdsContracts() {
    return SdsService.GetSdsContracts();
}

const Home: React.FunctionComponent<Props> = (props: Props) => {
    const [contracts, set_contracts] = useState([])
    const [is_loading, set_loading] = useState(true);

    useEffect(() => {
        async function getContracts() {
            set_contracts(await loadSdsContracts());
            set_loading(false);
        };
        getContracts();
    }, [])

    if(is_loading === true) {
        return (<Loading isActive={true} />)
    }

    return (<div>
        <div>
            <IressText element={TextElement.H1}>The Library</IressText>
            <IressText element={TextElement.P}>All MSO documentation can be accessed through The Library</IressText>
        </div>
{/*
        <Fieldset>
            <Label label="" />
            <SingleSelect
                value={undefined}
                onChange={e => {return null}}
                placeholder="Select a Contract to View..."
                fullWidth
            >
                {contracts.map((item: any) => (
                    <SingleSelect.Option
                        key={item.value}
                        value={item.value}
                    >
                        {item.label}
                    </SingleSelect.Option>
                ))}
            </SingleSelect>
        </Fieldset> */}


        <IressRow gutter={RowGutter.Lg} alignItems={RowAlignItems.Stretch}>
            <Section
                imageUri={dataContractimage}
                title='Data Contracts'
                description='These documents detail the data used within different parts of the MSO system'
                linkUrl='http://data-contracts.mso.devel.iress.com.au/#/latest' />

            <Section
                imageUri={fdsImage}
                title='Functional Design Specifications'
                description='These documents detail the high level system processes'
                linkUrl='fds' />

            <Section
                imageUri={implementationGuidesImage}
                title='Implementation Guides'
                description='These documents support the implementation of MSO features'
                linkUrl='implementation-guides' />

            <Section
                imageUri={sdsImage}
                title='Service Design Specifications'
                description='This section contains resources and documentation connected with integrating MSO with external systems'
                linkUrl='http://integration.docco.mso.devel.iress.com.au/#/home' />

            <Section
                imageUri={userGuidesImage}
                title='User Guides'
                description='These documents provide step by step instructions for using certain parts of MSO'
                linkUrl='user-guides' />
        </IressRow>
    </div>);
}

export default Home;