import React from 'react';

class DataContracts extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (<div>This is DataContracts</div>);
    }
}

export default DataContracts;