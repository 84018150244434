import React from 'react';
import { Nav, NavBar, NavItem } from '@iress/oui';
import logo from '../images/logo.png';
import { IressThemeSwitcher } from "@iress/components-react";

interface NavbarProps {

}

interface StateProps {

}

interface DispatchProps {

}

type Props = NavbarProps & StateProps & DispatchProps;

const navBar: React.FunctionComponent<Props> = (props: Props) => (
    <NavBar color={NavBar.Color.Primary} theme={NavBar.Theme.Dark}>
    <Nav>
      <NavItem
        label="Logo"
        labelHidden
        className="navbar-brand"
        mobileIconName="home"
        href="/"
      >
        <img src={logo} alt="IRESS" />
      </NavItem>
    </Nav>
    <span className="navbar-text">The Library</span>
    <Nav rightAlign>
        <IressThemeSwitcher className='i-nav-item nav-item' options='[{"label": "Light", "value": "iress-theme-light"},{"label": "Dark", "value": "iress-theme-dark"}]'></IressThemeSwitcher>
        <NavItem iconName="logout" label="Logout" />
    </Nav>
</NavBar>
)

export default navBar;