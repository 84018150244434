export default `# Task Administration System

## Introduction

This user guide describes the processes that can be initiated from or carried out in the Task Administration System (TAS) module of MSO. These processes include:

**Workload Monitoring** - The ability to monitor current work volumes through flexible views of the tasks within the system.

**Performance Monitoring** - The ability to identify tasks at risk of falling outside defined service levels.

**Task Allocation** - The ability to allocate or re-allocate tasks outside of the automated task allocation process, to individual users or recycle ‘owned’ tasks back into the task queue. Note that allocation or re-allocation can take place on cases already locked by a MSO user but that the task can only be actioned once the allocated user has been able to take the case lock themselves.

## Dashboard

The TAS dashboard presents user defined views of the tasks within the live system. These can be filtered by Teams, Roles, Task details and Case details. Each of these areas can be further configured (see individual sections below) to show specific tasks within that view.

For each view a panel shows the total number of tasks together with KPIs for ‘Overdue’, ‘At risk’, ‘Due today’ and ‘Due week’ figures derived from the SLA which is configured on the task. A task is defined as ‘Overdue’ when the effort required to complete the task is greater than the remaining time left before breaching the SLA. A task is defined as ‘At risk’ when it is within the tolerance (hours/minutes specified in the view) of becoming ‘Overdue’. The panels can be arranged in any order and can be assigned different colours.

![Dashboard View](Images/Dashboard-View.png)

Please note that the last refreshed time will reflect the count of tasks from the last time the panel was refreshed. Click ‘Refresh’ for a real time count.

![View Panels](Images/TAS-View-Panels.png)

Double click on a panel to view the results.

## How to change the view title

You can change the title of the view by hovering your mouse over the title. The edit icon will appear.

1. The full title will be shown if it is too long to display
1. Click the edit icon to edit the title

   ![Edit View Title](Images/Edit-View-Title.png)

1. Edit the title and click save

   ![Save View Title](Images/Save-View-Title.png)

1. Click the coloured side panel to change the colour
1. A colour palette will slide out for you to select a colour

   ![Change View Colour](Images/View-Colour-Palette.png)

## How to create a view

### Create view wizard

User defined views are created using the Create View wizard which will take you through each of the available sections.

From the dashboard:

1. Click on ‘Create View’
1. View Title, enter the name of the view which will be displayed on the dashboard
1. Select a colour for the side panel of the view or leave white
1. Select your filters, each view can be filtered by the following:
   - Teams or Roles
   - Task details and/or
   - Case details
1. Set At-risk Tolerance

![Create View Wizard](Images/Create-View-Wizard.png)

### Filtering by roles

This filter ensures that the task view is limited to only those tasks that are assigned to the selected roles.

1. Click ‘Select all’ to select all the roles
1. Expand the role to see who has been allocated to that role
1. Select individual user(s) within that role
1. Tick the top level tick box to select all people assigned to that role

![View Role Filter](Images/Role-Filter.png)

## Filtering by tasks

Tasks are filtered on their status and type of task.

### Filtering by task status

This filter ensures that the view is limited to only those tasks that have the selected status. You can select one or more status to filter by.

![Task Status Filter](Images/Task-Status-Filter.png)

### Filtering by task type

This filter ensures that the task view is limited to only those tasks types that are selected. Task types are grouped logically into categories which the user can expand to view the types within where one or more tasks can be selected. You can select a group of tasks or individual tasks. For performance reasons, we do not recommend you select all the tasks.

1. Expand the task to see all the tasks
1. Select one or more task
1. Tick the top level box to select all the tasks in that group

![Task Type Filter](Images/Task-Type-Filter.png)

### Filtering by teams

This filter ensures that the task view is limited to only those tasks that are already assigned to the selected teams / users. This view will not display tasks with a status of ‘Available’ as these are not assigned yet. Teams are configured in User Management. A team can have members of different roles assigned to it.

To filter by teams:

1. Select all teams or
1. Select individual member(s)
1. Expand the list see all team members
1. Tick the top level tick box to select all team members
1. Click to finish creating the view

![Team Filter](Images/Team-Filter.png)

### Filtering by case details

You can filter cases by their stage, along with the respective sales channels and brands available. You can select one or more case stages.

![Case Details Filter](Images/Case-Details-Filter.png)

When you have finished selecting all of you filters, click on ‘Finish Creation’. This is the last page of the View creation wizard and the results of the filtering will be shown on the screen.

## View results

The results of your chosen view are displayed.

![View Results](Images/View-Results.png)

Quick filters are available to quickly filter the list of tasks to those meeting the following criteria:

- Total tasks
- Tasks overdue
- Tasks at risk
- Tasks due today
- Tasks due this week (in the next 7 days, today plus 6 days)

The allocate task functionality allows a Task Administrator to manually assign an individual task to a specific Originations User, using a full or partial look-up of the user based on user identifier. A confirmation screen is shown prior to the allocation of the task to confirm the choice made.
The details of each task are shown.

1. Refresh the view
1. Edit the view
1. Reset the escalation of a task
   - Only tasks which have been escalated can have the escalation target removed
   - Only one task can be reset at a time
1. Allocate a task to another user
   - Only tasks that have a status of ‘Available’, ‘Owned’ or ‘Paused’ can be allocated
   - Only one task can be allocated at a time
1. Recycle one or more tasks to be made available via ‘Get next task’ from the Desktop tab ‘My Tasks’
   - Only tasks that have a status of ‘Owned’ or ‘Paused’ can be recycled
1. Use the Quick links to filter by overdue tasks, at risk tasks, tasks due today or tasks due this week
1. Clicking on the Case ID will provide more information about the case,including the classifiers which are assigned to that task instance
1. Clicking on any entry will highlight the task for further actions
1. Clicking the task again will deselect it

![Enhanced View Results](Images/Enhanced-View-Results.png)

If you click on the Case id, more details about that case are displayed.

![Case Information](Images/Case-Information.png)

## Resetting task escalation

Reset removes the escalation target when a User has chosen to escalate a task to a specific role. Only a single task can have the escalation reset at any one time.

The TAS User is given a confirmation summary before they confirm that they would like to reset the task escalation:

![Reset Task Escalation](Images/Reset-Task-Escalation.png)

## Recycling tasks

Recycle Tasks removes the ownership of the selected task and makes it available for allocation via Get Next Task in Originations.

- The user may select one or more tasks to Recycle
- Only tasks that have a status of ‘Owned’ or ‘Paused’ can be recycled
- Click on a task to select it, or deselect it
- You can select more than one task at a time

If you select a task that cannot be recycled, a warning message is displayed.

![Recycle Tasks Warning Label](Images/Recycle-Tasks-Warning-Label.png)

1. Click on the task or tasks you want to recycle
1. Click on ‘Recycle tasks’
1. Details of the tasks will display
1. Click ‘Continue’ to recycle the task

![Recycle Tasks Confirmation](Images/Recycle-Tasks-Confirmation.png)

## Allocating tasks

You can allocate a task to a specific user. Only one task can be selected at a time and only those that are either ‘Owned’, ‘Paused’ or ‘Available’ can be allocated. Click on a task to select it, or deselect it. If the user you choose to allocate the task to is not able to perform that task, you will see an error message and will not be able to allocate the task to that user.

If you select more than one task to be allocated, a warning message is displayed.

![Allocate Multiple Tasks Warning Label](Images/Allocate-Multiple-Tasks-Warning-Label.png)

To allocate a task:

1. Click on the task to allocate
1. Click on ‘Allocate task’
1. Details of the task will display
1. Search for a user using a full or partial look-up of the user based on user identifier
1. Click Continue

![Allocate Task](Images/Allocate-Task.png)

A confirmation screen is shown prior to the allocation of the task to confirm the choice made.

![Allocate Task Confirmation](Images/Allocate-Task-Confirmation.png)

If the selected user does not have the required credentials to perform the task, a warning message is displayed to prevent allocation.

![Invalid Credentials For Allocated Task](Images/Invalid-Credentials-For-Allocated-Task.png)

## Changing security questions and answers

The process for changing your security question and answers can be initiated from within any MSO back office system where that system is configured to authenticate users internally within MSO; this includes TAS.

1. From the TAS homepage click on your username from within the header bar, a dropdown list will appear.
1. Click on the [Edit your security questions] link in the dropdown list.
1. On the new page select three new security questions.
1. Enter the answers to the security questions.
1. Enter your account password.
1. Click the [Submit] button.

It’s important to note that for security reasons MSO will not display your existing security questions and answers, and therefore, MSO requires you to submit a full set of three security questions and answers when updating; i.e. it is not possible to only update one or two of the questions and answers during the process.

The answers to your security questions are stored salted and hashed in the User Management database.

These security questions and answers are for your MSO account as a whole, and are used for any internally authenticated MSO site that you have access to.

![Edit Security Questions](Images/Edit-Security-Questions.png)

![Set New Security Questions](Images/Set-New-Security-Questions.png)

## Appendix A - Version History

| Release   | Updates                                                                                       |
| --------- | --------------------------------------------------------------------------------------------- |
| 2020.4.2  | Spelling correction and updated document format applied                                       |
| 2018.11.1 | **Epic MPM-15:** Updated section 3.5 to include reference to brand filtering                  |
| 2018.8.1  | Changed name of USG_MSO_Glossary in References appendix                                       |
| 2018.2.1  | **Feature 26353:** Addition of escalation reset option                                        |
| 2017.1.1  | **Feature 5543:** Security questions applicability based on internal user authentication type |
| 2016.7.1  | **Bug 1863:** Hours and minutes displayed in dates                                            |
| 2016.7.1  | **Bug 1864:** At risk tolerance introduced for a view                                         |
| 2016.7.1  | **Bug 7518:** Allocation possible on locked cases                                             |
| 2016.6.1  | **Feature 4527:** Forgotten password and edit security questions options added                |
| 2016.1.1  | Various amendments to readability of document following reviews.                              |
| 2015.10.1 | Issued                                                                                        |

## Appendix B - References

| Name of document                          | Description                                                                                                    |
| ----------------------------------------- | -------------------------------------------------------------------------------------------------------------- |
| DCO_MSO_UserManagementDataContractExtract | Document describing the User Management screen data and supporting functionality.                              |
| USG_MSO_Glossary                          | Document containing a glossary of terms used throughout the Iress MSO system and its supporting documentation. |
| USG_MSO_UserManagement                    | A user guide for the User Management module.                                                                   |

## Appendix C - Terminology and Abbreviations

| Term | Description                                      |
| ---- | ------------------------------------------------ |
| KPI  | Key Performance Indicator                        |
| MSO  | The Iress Mortgage Sales and Originations System |
| SLA  | Service Level Agreement                          |
| TAS  | Task Administration System module                |
`