import React from 'react';

class ImplementationGuides extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (<div>This is ImplementationGuides</div>);
    }
}

export default ImplementationGuides;