import axios, { AxiosRequestConfig }  from 'axios';

export class SdsService {
    public static async GetSdsContracts(): Promise<any>{
        let token = undefined;
        
        const tokenStored = localStorage.getItem('okta-token-storage');
        if(tokenStored){
            const oktaStored = JSON.parse(tokenStored);
            token = oktaStored.accessToken.accessToken;
        }

        try{
            const config: AxiosRequestConfig = {headers: {
                Accept: 'application/json',
                'cache-control': 'no-cache',
                Authorization: `${token}`,
            }}

            const response = await axios.get<any>(`v1/documentation`, config);
            return await response.data;
        }
        catch(e) {
            throw new Error(e);
        }
    }
}