import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';
import HeaderNavBar from './components/navBar';
import Home from './components/home/homePage';
import Loading from './components/loading';
import { AuthenticationService } from './services/authenticationService';
import './App.scss';
import UserGuides from './components/user-guides/user-guides';
import Sds from './components/sds/sds';
import Fds from './components/fds/fds';
import DataContracts from './components/data-contracts/data-contracts';
import ImplementationGuides from './components/implementation-guides/implementation-guides';
import DocumentPacks from './components/document-packs/document-packs';
import { IressContainer } from "@iress/components-react";

const App: React.FC = () => {

  const [okta_config, set_config] = useState(undefined);
  const [is_loading, set_loading] = useState(true);

  useEffect(() => {
    async function getConfig() {
      set_config(await AuthenticationService.getConfiguration(window.location.origin));
      set_loading(false);
    }
    getConfig();
  }, []);

  if (is_loading === true) {
    return (<Loading isActive={true} />)
  }

  return (
    <div>
      <HeaderNavBar />
      <IressContainer>
        <Router>
          <Security {...okta_config}>
            <Route path='/implicit/callback' component={ImplicitCallback} />
            <SecureRoute path='/' exact={true} component={Home} />
            <SecureRoute path='/user-guides' exact={true} component={UserGuides} />
            <SecureRoute path='/sds' exact={true} component={Sds} />
            <SecureRoute path='/fds' exact={true} component={Fds} />
            <SecureRoute path='/data-contracts' exact={true} component={DataContracts} />
            <SecureRoute path='/implementation-guides' exact={true} component={ImplementationGuides} />
            <SecureRoute path='/document-packs' exact={true} component={DocumentPacks} />
          </Security>
        </Router>
      </IressContainer>
    </div>
  );
}

export default App;
