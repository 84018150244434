export default `
## ­Introduction

This user guide describes the features available in the Application
Support module of MSO. The Application support module replaces some of
the functionality currently provided by the Investigate System Error
Task. The key features are broken in to the following categories

> **Monitoring** -- The ability to proactively monitor and reactively
> search issues raised by MSO.
>
> **Diagnosis** -- The ability to correlate logs to a specific issue
> raised by MSO so that it aids the support user to diagnose the root
> cause of an issue.
>
> **Import/Export** -- The ability to export/import logs so that it can
> packaged and sent across to different specialist team to diagnose.
>
> **Management** -- The ability to restart a specific case or all cases
> affected by a particular issue and associating resolution notes that
> act as a knowledge base.

## Monitoring

## Filtering Queue

This is the default view displayed as part of the Home page. It assists
the MSO support user to perform a pro-active monitoring of the system.
It gives a holistic view of the current exceptions and the number of
cases that have been affected. The queue can be filtered by one or more
Support Item Types and/or one or more Case Statuses and/or one or more
Case Stages. It allows the MSO support user to group errors accordingly
and provide additional focus to production errors.

![](./images/image2.png)

![](./images/image3.png)

## Exception details

Clicking on the exception type reveals the exception details at a case
level as shown below.

![](./images/image4.png)

## Knowledge Base

![](./images/image5.png)

## Search

This view assists the support user to perform any reactive monitoring of
the system i.e. where an originations user requires assistance to
identify why a specific case has not progressed or has encountered an
exception.

![](./images/image6.png)

![](./images/image7.png)

## UI Issues

Any issues encountered by MSO UI applications (Intermediary Sales,
Originations Desktop etc.) when communicating with an API are allocated
a unique exception identifier and reported back on to the UI in the form
of an error toaster.

The UI issues view assists the lender's support user to search using the
unique exception identifier when the UI user raises it with them.

![](./images/image8.png)

## Data Extract Queue

As a case progresses through MSO, it passes through a number of modules.
Each of these modules is responsible for raising events when key
business activities occur. The Data extract event subscriber listens to
a subset of these events and when an event is raised that the data
extract is interested in, a request to extract the latest case data is
logged on the data extract queue. A data extract process will then pick
up the request to extract the data and process it. If this process
errors at any point the status of the request is marked as failed on the
date extract queue for that case.

This is a copy of that ExtractorQueue table and if you click on the
friendlyId or CaseId column, the records shown on screen will filter to
that selected case, alternatively the search facility on screen enables
you to manually enter the friendly id or case id.

You are also able to filter the records based on their status e.g
Complete, Failed or Pending.

![](./images/image9.png)

Where a record has failed to be extracted, there is the added "copy to
clipboard" functionality. This will copy the content of the "LastError"
column for the selected record.

![](./images/image10.png)

## Data Extract Error Log

At a predetermined time, an SSIS package is executed, this package moves
data from the staging databases to the published databases. Any errors
that occur with this process are logged in the Data Extract Error log.
The most recent error log entry will appear at the top.

![](./images/image11.png)

## Data Extract Delete Failures

The SSIS job also refreshes a number of published tables on bulk (i.e.
it deletes the data and completely reloads it, rather than incrementally
updating with only new data). Any errors that occur with this process
are logged in the Data extract delete failures table.

The records are sorted with the latest error appearing at the top of the
table.

![](./images/image12.png)

## Data Extract Executions

During the execution of the Data Extract SSIS job a number of messages
are logged to an internal database. The Data Extract Executions tab
provides the user with a summary outcome of all Data Extract SSIS runs,
utilising the aforementioned database to provide additional context.
This tab will allow a user to see whether a specific run has passed, or
if any errors were encountered during processing.

![](./images/image13.png)

Depending on the outcome of the SSIS run you can potentially see a
number of different Status Messages. The following table describes the
messages you can see, and what they mean:

|  **Status Message**                                                                                                                                          |  **Description**                                                                                                                                                                                                                                         |
|--------------------------------------------------------------------------------------------------------------------------------------------------------------|----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
|  Package Succeeded                                                                                                                                           |  SSIS run has been successful,  Data Extract has occurred  without any data integrity  issues                                                                                                                                                            |
|  Package Succeeded with Warning                                                                                                                              |  SSIS run has been successful,  Data Extract has occurred  without any data integrity  issues, however something went  wrong during processing that the  user should be aware of.   The information in the Error  Message column will help to  diagnose. |
|  Package Failed with error: SSIS  execution could not trigger due  to the data extraction process  not finishing within the  pre-configured SSIS poll window |  SSIS run has occurred, but no  Data Extraction has taken place.   Another process currently has  control over the data extraction  process (e.g. a case could be  stuck in the extractor queue)  thus preventing the Data Extract  run from starting    |
|  Package Failed with error:  Another SSIS execution is  already in progress and  therefore this execution is  being cancelled.                               |  SSIS run has occurred, but no  Data Extraction has taken place.   This is because another SSIS run  is already in progress, and MSO  only allows one run at a time.                                                                                     |
|  Package Failed with error: SSIS  execution failed processing due  to an unexpected error                                                                    |  SSIS run has occurred, but no  Data Extraction has taken place.   An unexpected error has occurred  whilst Data Extraction was  taking place, resulting in a  failed Data Extract. The  information in the Error Message  column will help to diagnose. |

## Diagnostics

Support users diagnose any issue by analysing logs. MSO instrumentation
has 2 variants

1.  Internal -- Stored in the internal MSO data store and has no
    external dependencies to surface this information.

2.  External -- Constitutes of Workflow Tracking, Event Viewer, IIS Logs
    & ETW logs and has an external dependency on a suitable store and
    API to surface this information. MSO supports the ELK stack out of
    the box. Lenders who choose to use their own custom implementation
    will have to build an Adaptor that abides to the following SDS so
    that the external log information could be surfaced.

    SDS\_MSO\_DV00539\_Outbound\_ApplicationSupport.html

## Internal Logs

This constitutes of key MSO infrastructure logs

1.  Workflows -- Identifies all the workflows associated to the case
    being analysed.

2.  Event Journal -- Identifies all the events raised and its journey
    taken through all subscriptions for the case being analysed.

3.  Message Journal -- Similar to above but a view emitted from the
    Messaging framework.

4.  Case Requirements -- Identifies all the case requirements and its
    corresponding history associated with the case being analysed.

5.  Tasks -- Identifies all the tasks and its corresponding history
    associated with the case being analysed.

![](./images/image14.png)

## SQL Logs

![](./images/image15.png)

## External Logs

![](./images/image14.png)

## Workflow Tracking

Only available when the source of the exception is a workflow process.

![](./images/image16.png)

## Event Viewer

Event viewer logs are correlated based on the exception date. By default
any exceptions raised 5 minutes either side of the exception are
retrieved.

![](./images/image17.png)

## IIS

Contains http requests recorded on the IIS log that match the trace
correlation identifier related to the exception.

![](./images/image18.png)

## ETW

Represents all the custom MSO related instrumentation events emitted and
related to the trace correlation identifier that matches the exception
source.

![](./images/image19.png)



## Import/Export

The export feature is to primarily support the following three
scenarios-

1.  Lenders that host MSO in their own private data centre. Export
    capability is a means of quickly and conveniently packaging up all
    the log data related to a specific exception to Iress for analysis.

2.  Lenders that host MSO in the Iress data centre. Export capability is
    a means of quickly and conveniently packaging up all the log data
    related to a specific exception to Iress internal product team.

3.  Means for testers on the internal product development team to export
    and attach logs to an observation or defect.

The import feature is a means of viewing any exported logs to aid issue
analysis. The import and export only support .JSON files.

## Export

## Exporting External Logs

![](./images/image14.png)

## Exporting Internal Logs

![](./images/image15.png)

## Exporting UI Issue Related Logs

![](./images/image8.png)

## Import

This feature will generally be used by --

1.  Iress support users -- Lender's first line support will export and
    send the logs associated with an issue to Iress support users for
    further analysis.

2.  Iress Product Support -- Iress Live support will export and send the
    logs associated with an issue to the Iress Product Support

3.  Product developers -- View the logs associated with an observation
    or defect.

![](./images/image20.png)

## Management

When MSO process (Workflow/Service Bus) encounters a non-transient
exception, the offending process is suspended and control is given to
the Application Support module to engage a support user to manually
resolve the underlying issue and resume/ignore the suspended process. If
the offending process is a workflow -- Ignore capability is not
supported.

## How to bulk restart all cases affected by an exception

Usually when there is an issue with an underlying service or third party
service, it affects multiple cases. When the support user resolves the
underlying issue with the dependent service it will be normal practice
to restart all cases (24 in the example below) that were affected by the
issue.

![](./images/image21.png)

![](./images/image22.png)

## How to bulk ignore all cases affected by a service bus exception

Option is only available when the offending process is a service bus
process. Workflow exceptions cannot be ignored. NOTE- Once the case
messages are ignored they can't be retrieved back and there is no way to
rerun that process.

![](./images/image21.png)

## How to restart a specific case affected by an exception

Option is available if the support user only wants to target a specific
case. Most likely scenario is when the underlying issue is related to
the case data and remedial action was taken on a specific case.

![](./images/image21.png)

![](./images/image23.png)

![](./images/image24.png)

## How to ignore a specific case affected by a service bus exception

Option is only available when the offending process is a service bus
process. Workflow exceptions cannot be ignored. NOTE- Once the case
message is ignored it can't be retrieved back and there is no way to
rerun that process.

![](./images/image25.png)

## Appendix A -- Version History

| Release   |   Updates                                                                                   |
|-----------|---------------------------------------------------------------------------------------------|
| 2020.6.1  |  Conversion of document into Markdown.                                                      |
| 2020.3.2  |  MPM-7406 Added Data Extract Executions tab.                                                |
| 2019.11.1 |  MPM-6095 Deprecate the pending queue & minor updates.                                      |
| 2019.10.1 |  MPM-2765 Application Support Data Extract Monitoring                                       |
| 2019.5.1  |  MPM-274 - Ability to filter the MSO Diagnostics view within AST by case stage and status   |
| 2017.7.1  |  Initial Version.                                                                           |

## Appendix B -- Terminology and Abbreviations

| Term  | Description  |
|--|--|
| ELK   | The **ELK stack** consists of Elasticsearch, Logstash, and Kibana. |
| ETW   | Event Tracing for Windows (**ETW**) is an efficient kernel-level tracing facility that lets you log kernel or application-defined events to a log file.   |
| MSO   | The Iress Mortgage Sales and Originations System.                                                                                                         |
`